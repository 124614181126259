html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.a-fadein {
  opacity: 0;
  will-change: opacity;
  transition: 1s opacity ease;
}

.a-fadein.in-view {
  opacity: 1;
}

.a-fadeup {
  opacity: 0;
  will-change: transform, opacity;
  transition: 1s opacity ease, 0.4s transform ease;
  transform: translateY(10px);
}

.a-fadeup.in-view {
  opacity: 1;
  transform: translateY(0);
}

.a-delay-200 {
  transition-delay: 0.2s;
}

.a-delay-400 {
  transition-delay: 0.4s;
}

.a-delay-600 {
  transition-delay: 0.6s;
}

.a-delay-800 {
  transition-delay: 0.8s;
}

.a-delay-1000 {
  transition-delay: 1s;
}

.a-delay-1200 {
  transition-delay: 1.2s;
}

.a-delay-1400 {
  transition-delay: 1.4s;
}

.a-delay-1600 {
  transition-delay: 1.6s;
}

.a-delay-1800 {
  transition-delay: 1.8s;
}

.a-delay-2000 {
  transition-delay: 2s;
}

html, body {
  width: 100%;
  height: 100%;
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  font: 14px/1.4 Arial, sans-serif;
  text-rendering: optimizeLegibility;
  background: var(--color-main-bg);
  color: var(--color-main-text);
}

:root {
  --color-main-bg: #f4aa44;
  --color-main-text: #333;
  --color-accent-bg: #fff;
  --color-accent-text: #12120f;
  --font-main: system-ui, sans-serif;
}

a {
  text-decoration: none;
  color: #333;
  color: var(--color-main-text);
}

a,
.o-link {
  position: relative;
  z-index: 1;
}

a::after,
.o-link::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: currentColor;
  opacity: .25;
  transform: scaleX(0);
  transform-origin: 100% 50%;
  transition: transform 0.3s cubic-bezier(0.4, 0, 0, 1);
}

a::after,
.o-link::after {
  transform: scaleX(1);
}

a:hover,
.o-link:hover {
  color: #f02937;
}

a:hover::after,
.o-link:hover::after {
  transform: scaleX(0);
}

.app {
  position: relative;
  padding: constant(safe-area-inset-top) constant(safe-area-inset-right) constant(safe-area-inset-bottom) constant(safe-area-inset-left);
  background: url(../img/bg.png) no-repeat 50% 50%/cover;
}

.main {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;
  width: 100%;
  max-width: 960px;
  position: relative;
}

.info {
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flare {
  position: absolute;
  top: -30%;
  left: -20%;
  width: 700px;
  height: 650px;
}

.flare:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(../img/flare.png) no-repeat 0 0/contain;
}

@media (max-width: 992px) {
  .flare {
    display: none;
  }
}

.logo {
  text-indent: -11984px;
  position: relative;
  width: calc(430px * var(--device-scale));
  height: calc(200px * var(--device-scale));
}

.logo:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: url(../img/logo.svg) no-repeat 50% 50%/contain;
}

.logo {
  --device-scale: .8;
}

.content {
  position: relative;
}

.content a {
  transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  position: relative;
}

.content p {
  position: relative;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 2rem;
  font-size: 18px;
  line-height: 160%;
}

.content p.contacts {
  text-transform: lowercase;
}

.content p.small {
  font-size: 12px;
  line-height: 200%;
}

@media (max-width: 992px) {
  .content p.small > a:after {
    display: none;
  }
}

.stores {
  width: 100%;
  position: relative;
  margin: 2rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 992px) {
  .stores {
    flex-direction: row;
  }
}

.stores__item {
  position: relative;
  margin-bottom: 2em;
  width: 180px;
  height: 64px;
  margin-right: 2%;
}

.stores__item:before {
  content: "";
  display: block;
  padding-top: 30.2%;
}

.stores__item img {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.stores__item .annotation {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  bottom: -24px;
  text-transform: uppercase;
  letter-spacing: .1em;
  font-size: 9px;
}

.stores__item {
  transition: all .3s ease 0s;
}

.stores__item::after {
  display: none;
}

.stores__item:hover {
  opacity: .8;
  transition: all .3s ease 0s;
}

.stores__item:active {
  transform: scale(0.95);
}

.info {
  flex: 0 60%;
  --device-scale: 0.8;
  margin-right: 40%;
  z-index: 1;
}

.hero {
  position: absolute;
  z-index: 0;
}

.hero {
  --device-scale: 0.8;
  right: -10%;
  top: 50%;
  transform: translate(0, -50%);
  width: calc(800px * var(--device-scale));
  height: calc(800px * var(--device-scale));
}

.hero img {
  width: 100%;
  height: 100%;
}

.bnipi-logo {
  height: 64px;
}

@media (max-width: 992px) {
  .main {
    height: initial;
  }
  .logo {
    --device-scale: .5;
  }
  .info {
    flex: 0 100%;
    margin-right: 0;
    padding-top: 250px;
    overflow-x: hidden;
  }
  .stores {
    flex-direction: column;
  }
  .hero {
    --device-scale: 0.25;
    top: 5%;
    right: 50%;
    transform: translate(50%, 0);
  }
}
