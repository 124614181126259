.a-fadein {
  opacity: 0;
  will-change: opacity;
  transition: 1s opacity ease;
  &.in-view {
    opacity: 1;
  }
}
.a-fadeup {
  opacity: 0;
  will-change: transform, opacity;
  transition: 1s opacity ease, 0.4s transform ease;
  transform: translateY(10px);
  &.in-view {
    opacity: 1;
    transform: translateY(0);
  }
}
  
$n: 20;
@for $i from 1 through $n {
  $i: $i+1;
  @if $i % 2 == 0 {
    .a-delay-#{($i)*100} {
      transition-delay: #{$i/10}s;
    }
  }
}