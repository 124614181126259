@import "reset.min";
@import "in-view";

// Transitions
// =============================================================================
$speed:  0.3s;
$speed-fast: ($speed * 0.5);
$speed-medium: ($speed * 1.5);
$speed-slow: ($speed * 2);
$speed-medium-slow: ($speed * 2.5);
$speed-slower: ($speed * 3);
$speed-slower-medium: ($speed * 3.5);
$speed-slowest: ($speed * 4);
$easing: cubic-bezier(0.4, 0, 0, 1);
$easingBounce: cubic-bezier(.6,.01,0,1.38);

html, body {
  width: 100%;
  height: 100%;
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  font: 14px/1.4 Arial, sans-serif;
  text-rendering: optimizeLegibility;
  background: var(--color-main-bg);
  color: var(--color-main-text);

  $mapFontSize: (
      320px: 12px,
      1366px: 14px,
  );
  // @include poly-fluid-sizing('font-size', $mapFontSize);
}

:root {
  --color-main-bg: #f4aa44;
  --color-main-text: #333;
  --color-accent-bg: #fff;
  --color-accent-text: #12120f;
  --font-main: system-ui, sans-serif;
}

a {
  text-decoration: none;
  color: #333;
  color: var(--color-main-text);
}

a,
.o-link {
  position: relative;
  z-index: 1;
  
  &::after {
    content:"";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: currentColor;
    opacity: .25;
    transform: scaleX(0);
    transform-origin: 100% 50%;
    transition: transform $speed $easing;
  }

    &::after {
      transform: scaleX(1);
    }
    &:hover {
      // color: var(--color-accent-text);
      color: #f02937;
      &::after {
        transform: scaleX(0);
      }
    }
}





.app {
  position: relative;
  padding: constant(safe-area-inset-top) constant(safe-area-inset-right) constant(safe-area-inset-bottom) constant(safe-area-inset-left);
  background: url(../img/bg.png) no-repeat 50% 50%/cover;
  // overflow: hidden;
}

.main {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;
  width: 100%;
  max-width: 960px;
  position: relative;
}
.info {
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  // min-height: 600px;
}
.flare {
  position: absolute;
  top: -30%;
  left: -20%;
  width: 700px;
  height: 650px;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(../img/flare.png) no-repeat 0 0/contain;
  }
  @media (max-width: 992px) {
    display: none;
  }
}
.logo {
  text-indent: -11984px;
  position: relative;
  width: calc(430px * var(--device-scale));
  height: calc(200px * var(--device-scale));
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: url(../img/logo.svg) no-repeat 50% 50%/contain;
  }
}
.logo {
  --device-scale: .8;
}
.content {
  position: relative;
  a {
    transition: color .3s cubic-bezier(.215,.61,.355,1);
    position: relative;
    &:after {

    }
  }
  p {
    position: relative;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 2rem;
    font-size: 18px;
    line-height: 160%;
    &.contacts {
      text-transform: lowercase;
    }
    &.small {
      font-size: 12px;
      line-height: 200%;
      > a {
        // white-space: nowrap;
        @media (max-width: 992px) {
          &:after {
            display: none;
          }
        }
      }
    }
  }
}
.stores {
  width: 100%;
  position: relative;
  margin: 2rem 0;

  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 992px) {
    flex-direction: row;
  }
  &__item {
    position: relative;
    margin-bottom: 2em;
    width: 180px;
    height: 64px;
    margin-right: 2%;
    &:before {
      content: "";
      display: block;
      padding-top: 30.2%;
    }
    img {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    .annotation {
      position: absolute;
      width: 100%;
      left: 0;
      right: 0;
      bottom: -24px;
      text-transform: uppercase;
      letter-spacing: .1em;
      font-size: 9px;
    }
    & {
      &::after {
        display: none;
      }
      transition: all .3s ease 0s;
    }
    &:hover {
      opacity: .8;
      transition: all .3s ease 0s;
    }
    &:active {
      transform: scale(.95);
    }
  }
}
.info {
  flex: 0 60%;
  --device-scale: 0.8;
  margin-right: 40%;
  z-index: 1;
}
.hero {
  position: absolute;
  z-index: 0;
}
.hero {
  --device-scale: 0.8;
  right: -10%;
  top: 50%;
  transform: translate(0, -50%);
  width: calc(800px * var(--device-scale));
  height: calc(800px * var(--device-scale));
  img {
    width: 100%;
    height: 100%;
  }
}

.bnipi-logo {
  height: 64px;
}



@media (max-width: 992px) {
  .main {
    height: initial;
  }
  .logo {
    --device-scale: .5;
  }
  .info {
    flex: 0 100%;
    margin-right: 0;
    padding-top: 250px;
    overflow-x: hidden;
  }
  .stores {
    flex-direction: column;
  }
  .hero {
    --device-scale: 0.25;
    top: 5%;
    right: 50%;
    transform: translate(50%, 0);
  }
}